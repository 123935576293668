const getPrevNext = (boothId) => {
  switch (boothId) {
    // hall A
    case 4: 
      return {
        prev: null,
        next: 5,
      }
    case 5: 
      return {
        prev: 4,
        next: 6,
      }
    case 6: 
      return {
        prev: 5,
        next: 7,
      }
    case 7: 
      return {
        prev: 6,
        next: 8,
      }
    case 8: 
      return {
        prev: 7,
        next: 9,
      }
    case 9: 
      return {
        prev: 8,
        next: 10,
      }
    case 10: 
      return {
        prev: 9,
        next: 11,
      }
    case 11: 
      return {
        prev: 10,
        next: 12,
      }
    case 12: 
      return {
        prev: 11,
        next: null,
      }
    // hall B
    case 13: 
      return {
        prev: null,
        next: 18,
      }
    case 18: 
      return {
        prev: 13,
        next: 19,
      }
    case 19: 
      return {
        prev: 18,
        next: 21,
      }
    case 21: 
      return {
        prev: 19,
        next: 22,
      }
    case 22: 
      return {
        prev: 21,
        next: 23,
      }
    case 23: 
      return {
        prev: 22,
        next: 24,
      }
    case 24: 
      return {
        prev: 23,
        next: 25,
      }
    case 25: 
      return {
        prev: 24,
        next: 26,
      }
    case 26: 
      return {
        prev: 25,
        next: null,
      }
    default:
      return {}
  }
}
const getButtonPos = (boothType) => {
  switch (boothType){
    case 1:
      return {
        vid: `
          top: 48.5%;
          left: 67.5%;
        `,
        image: `
          top: 50%;
          left: 40%;
        `,
        chat: `
          top: 67.5%;
          left: 67%;
        `,
        pdf: `
          top: 43%;
          left: 78.5%;
        `,
        info: `
          top: 6%;
          left: 73%;
        `,
      }
    case 2:
      return {
        vid: `
          top: 50%;
          left: 31.5%;
        `,
        image: `
          top: 50%;
          left: 60%;
        `,
        chat: `
          top: 72.5%;
          left: 25%;
        `,
        pdf: `
          top: 43%;
          left: 79.5%;
        `,
        info: `
          top: 12.5%;
          left: 40%;
        `,
      }
    case 3:
      return {
        vid: `
          top: 50.5%;
          left: 71.5%;
        `,
        image: `
          top: 45%;
          left: 35%;
        `,
        chat: `
          top: 67.5%;
          left: 82%;
        `,
        pdf: `
          top: 46%;
          left: 61%;
        `,
        info: `
          top: 8.5%;
          left: 64%;
        `,
      }
    case 4:
      return {
        vid: `
          top: 46%;
          left: 69%;
        `,
        image: `
          top: 50%;
          left: 35%;
        `,
        chat: `
          top: 71.5%;
          left: 66%;
        `,
        pdf: `
          top: 69%;
          left: 51%;
        `,
        info: `
          top: 18%;
          left: 63%;
        `,
        zoom: `
          top: 82.5%;
          left: 72.5%;
        `,
      }
    case 5:
      return {
        vid: `
          top: 48%;
          left: 65.5%;
        `,
        image: `
          top: 50%;
          left: 32%;
        `,
        chat: `
          top: 71.5%;
          left: 66%;
        `,
        pdf: `
          top: 65.5%;
          left: 48.5%;
        `,
        info: `
          top: 18%;
          left: 43%;
        `,
      }
    default:
      return {}
  }
}
export { getButtonPos, getPrevNext };