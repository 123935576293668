import React from 'react'
import { Modal } from 'react-bootstrap'
import agenda from '../../images/event/EMC-agenda.jpg'
import { COLORS } from '../../styles/theme'
import styled from 'styled-components'

const CustomModal = styled(Modal)`
  .modal-content {
    border: 2px solid ${COLORS.primaryLight};
    border-radius: 28px;
    box-shadow: 0px 4px 12px ${COLORS.lightGray1};
    padding: 16px;
  }
  .modal-header {
    border: none;
    padding: 0 16px;
  }
  .modal-body {
    padding: 0 16px;
  }
  .btn {
    display: block;
    width: 100%;
    margin-left: auto;
    border: none;
    border-radius: 28px;
    padding: 6px 16px;
    background: ${COLORS.primaryLight};
  }
  textarea {
    border-radius: 10px;
    padding: 10px 15px;
    border: solid 2px #707070;
  }
`

export default function AlertModal({ showModal, hideSchedule }) {
  return (
    <CustomModal
      show={showModal}
      onHide={hideSchedule}
      size="md"
      aria-labelledby="message"
      centered
    >
      <Modal.Body>
        <h5 className="py-5 px-2 px-md-5 text-center">
          This will be customer service chat widget integration.
        </h5>
      </Modal.Body>
    </CustomModal>
  )
}
