import React from 'react'
import styled from 'styled-components'
import { Modal, Carousel } from 'react-bootstrap'
import { COLORS } from '../../styles/theme'

const StyledCarousel = styled(Carousel)`
  .carousel-control-next, 
  .carousel-control-prev {
    opacity: 1;
  }
  .carousel-control-prev {
    left: -8%;
  } 
  .carousel-control-next {
    right: -8%;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-image: none;
  }

  span.carousel-control-next-icon:after {
    content: '>';
    font-size: 35px;
    font-weight: 700;
    color: ${COLORS.primaryTrans};
  }

  span.carousel-control-prev-icon:after {
    content: '<';
    font-size: 35px;
    font-weight: 700;
    color: ${COLORS.primaryTrans};
  }
`
export default function PosterModal({ images, posterOpen, setPosterOpen }) {
  return (
    <Modal
      show={posterOpen}
      onHide={()=>setPosterOpen(false)}
      aria-labelledby="images-modal"
      centered
      size="xl"
    > 
      <Modal.Body>
        {
          images.length > 1 ?
          <StyledCarousel>
            {
              images.map((poster,index)=>(
                <Carousel.Item key={`poster-${index}`} interval={4000}>
                  <img
                    className="d-block w-100"
                    src={poster.url}
                    alt={`poster-${index}`}
                  />
                </Carousel.Item>
              ))
            }
          </StyledCarousel>
          :
          <img
            className="d-block w-100"
            src={images[0].url}
            alt="poster"
          />
        }
      </Modal.Body>
    </Modal>
  )
}
