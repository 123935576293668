import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import amplitude from 'amplitude-js'

import routes from '../../routes'

import LoadIcon from '../../components/LoadIcon'
import ChatSection from '../../components/ChatSection.js'
import SideAgenda from '../../components/SideAgenda.js'

import { muteMusic, unmuteMusic } from '../../ducks/layout'

// import checkin from '../../images/mobile/checkin.png';
// import mobileMc20 from '../../images/mobile/mobile-mc20.png';
import screen from '../../images/mobile/screen.png'
import checkin from '../../images/mobile/checkin.png'
import mc20Bg from '../../images/mobile/mc20-bg.jpg'
import mc20Logo from '../../images/mobile/mc20-logo.png'
import agenda from '../../images/mobile/agenda.png'
import speakers from '../../images/mobile/speakers.png'
import agenda3 from '../../images/mobile/agenda3.jpg'
import speaker7 from '../../images/mobile/speaker7.jpg'
import { ChatMcDb } from '../../firebaseConfig'
import openTheatre from '../../images/mobile/open-theatre.png'
import closeTheatre from '../../images/mobile/close-theatre.png'

const MobileMC20 = ({ currentUser }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    amplitude.getInstance().logEvent(`Visit MC20 Auditorium`)
  }, [currentUser.id])

  useEffect(() => {
    dispatch(muteMusic())

    return () => {
      if (localStorage.getItem('muted') === 'false') {
        dispatch(unmuteMusic())
      }
    }
  }, [dispatch])

  const [loading, setLoading] = useState(true)
  const [sidebar, setSidebar] = useState(false)
  const [agendaModal, setAgendaModal] = useState(false)
  const [speakerModal, setSpeakerModal] = useState(false)
  const [theatre, setTheatre] = useState(false)

  const toggleSidebar = () => {
    if (sidebar === false)
      amplitude.getInstance().logEvent(`Click MC20 checkin list`)
    setSidebar(!sidebar)
  }
  const toggleAgendaModal = () => {
    if (agendaModal === false)
      amplitude.getInstance().logEvent('Click MC20 agenda modal')
    setAgendaModal(!agendaModal)
  }

  const toggleSpeakerModal = () => {
    if (speakerModal === false)
      amplitude.getInstance().logEvent('Click MC20 speaker modal')
    setSpeakerModal(!speakerModal)
  }

  const videoContainerTheatreStyle = {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'black',
    zIndex: '99',
  }

  if (currentUser.role !== 'manager') {
    return <Redirect to={routes.lobby} />
  }

  return (
    <div>
      <SideAgenda
        open={sidebar}
        toggleSidebar={toggleSidebar}
        hallNumber={2}
        currentUser={currentUser}
      />

      <Page>
        {agendaModal && (
          <div className="modal-container">
            <p className="modal-container-close" onClick={toggleAgendaModal}>
              Close
            </p>
            <div className="modal-container-content">
              <img alt="" src={agenda3} className="content-image" />
            </div>
          </div>
        )}

        {speakerModal && (
          <div className="modal-container">
            <p className="modal-container-close" onClick={toggleSpeakerModal}>
              Close
            </p>
            <div className="modal-container-content">
              <img alt="" src={speaker7} className="content-image" />
            </div>
          </div>
        )}

        <div className="outer-wrapper">
          <div className="container">
            <div className="checkin-container">
              <img alt="" src={mc20Logo} className="logo" />
              <img
                alt=""
                src={checkin}
                className="checkin"
                onClick={toggleSidebar}
              />
            </div>

            <div className="screen-container">
              <img alt="" src={screen} className="screen" />
              <div
                className="video-container"
                style={theatre ? videoContainerTheatreStyle : {}}
              >
                {loading && <LoadIcon />}
                <iframe
                  title="Live Broadcast"
                  src="https://player.vimeo.com/video/490388481?autoplay=1"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  frameBorder="0"
                  onLoad={() => {
                    setLoading(false)
                  }}
                />
              </div>
            </div>

            <button
              type="button"
              className="theatre-button"
              onClick={() => setTheatre(true)}
            >
              <img alt="" src={openTheatre} className="theatre-button-icon" />
              <p className="theatre-button-label">Theatre mode</p>
            </button>

            <div className="buttons-container">
              <img
                alt=""
                src={agenda}
                className="agenda"
                onClick={toggleAgendaModal}
              />
              <img
                alt=""
                src={speakers}
                className="speakers"
                onClick={toggleSpeakerModal}
              />
            </div>
          </div>

          <div className="chat-container">
            <ChatSection
              currentUser={currentUser}
              firebaseDb={ChatMcDb}
              hallName="MC20"
            />
          </div>

          {theatre && (
            <div className="theatre-close">
              <img
                alt=""
                src={closeTheatre}
                onClick={() => setTheatre(false)}
                className="theatre-close-icon"
              />
            </div>
          )}
        </div>
      </Page>
    </div>
  )
}

// 14 Dec, 15 Dec, 16 Dec
// 490388481

const Page = styled.div`
  background: url(${mc20Bg});
  background-size: cover;
  background-position: 50% 50%;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 95%;
    margin: auto;
  }

  .checkin-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /*background: orange;*/
    margin: 30px 0px 20px 0px;
  }

  .checkin {
    width: 38%;
    height: auto;
  }

  .logo {
    width: 50%;
    height: auto;
  }

  .screen-container {
    width: 100%;
    height: 0;
    padding-bottom: 58.5%;
    /*background: green;*/
    position: relative;
  }

  .screen {
    width: 100%;
    position: absolute;
  }

  .video-container {
    width: 92%;
    height: 81%;
    background: #1e1e1e;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50.5%, -51%);

    iframe {
      height: 100%;
      width: 100%;
    }
  }

  .buttons-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
  }

  .agenda {
    width: 45%;
    height: auto;
  }

  .speakers {
    width: 45%;
    height: auto;
  }

  .chat-container {
    width: 100%;
    height: 500px;
  }

  .modal-container {
    width: 95%;
    height: 60%;
    border-radius: 30px;
    border: solid 2px #652781;
    position: fixed;
    bottom: 1%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    z-index: 99;
  }

  .modal-container-close {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
    margin-bottom: 0;
    position: absolute;
    top: 20px;
    right: 30px;
  }

  .modal-container-content {
    width: calc(100% - 60px);
    height: calc(100% - 70px);
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    overflow-y: scroll;
  }

  .content-image {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .theatre-button {
    width: 140px;
    height: 30px;
    background: black;
    margin: 10px 10px 20px 0px;
    float: right;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    opacity: 0.6;
    padding: 0;
  }

  .theatre-button-label {
    color: white;
    font-size: 12px;
    margin: 0px 10px 0px 0px;
  }

  .theatre-button-icon {
    width: 20px;
    margin: 0px 10px;
  }

  .theatre-close {
    color: white;
    background: black;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 99;
    opacity: 0.6;
  }

  .theatre-close-icon {
    width: 20px;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }

    .modal-container {
      max-width: calc(0.95 * 540px);
    }
  }
`

export default MobileMC20
