import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { COLORS } from '../styles/theme'
import Axios from 'axios'
import config from '../config'
import KfairAgendaItem from './KfairAgendaItem'
import ModalVideo from 'react-modal-video'

const AgendaWrapper = styled.div`
  padding: 32px 0;
  header {
    .toggle-button {
      background-image: linear-gradient(#71030b, #270d48);
      outline: none;
      border-radius: 32px;
      color: white;
      padding: 6px 16px;
      border: 1px solid white;
      margin-right: 32px;
      font-size: 18px;
      cursor: pointer;
      &:hover:not(.active) {
        color: ${COLORS.primary};
        background: #ffad2c;
      }
    }
    .active {
      color: #ffad2c;
      border: 2px solid #ffad2c;
      font-style: italic;
      font-weight: bold;
      text-decoration: underline;
    }
  }
  section.agenda-container {
    padding: 32px 0;
    .agenda-item {
      .end {
        color: #ffad2c;
      }
      .attend,
      .replay {
        border: none;
        background: transparent;
        height: 30px;
        width: 50px;
        margin: 0;
        p.load {
          margin: 0;
          font-weight: 500;
          color: #ffad2c;
          font-size: 8px;
        }
        img {
          height: 100%;
        }
        .icon-label {
          text-align: center;
          color: white;
          font-size: 1px;
          opacity: 0;
        }
        &:hover {
          img.pending {
            transform: scale(0.6);
          }
          .icon-label {
            font-size: 6.5px;
            opacity: 1;
          }
        }
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`

export default function KfairAgenda({ currentUser }) {
  const [selected, setSelected] = useState(true)
  const [ agendaList, setAgendaList ] = useState([
    {
      id: 1,
      start: "11:00 AM",
      end: "12:15 AM",
      desc: "Stay Safe Online: Take Control of Your Digital Life",
      attendance: false,
      video: false,
    },
    {
      id: 2,
      start: "02:15 PM",
      end: "02:30 PM",
      desc: "Conquering 2020 in the New Normal",
      attendance: false,
      video: false,
    },
    {
      id: 3,
      start: "02:45 PM",
      end: "03:30 PM",
      desc: "Pandemic Crisis: Global Economic Recovery",
      attendance: false,
      video: false,
    },
    {
      id: 4,
      start: "03:45 PM",
      end: "04:30 PM",
      desc: "Dealing with Change: Turning the Mind Towards Acceptance",
      attendance: false,
      video: false,
    },
    {
      id: 5,
      start: "04:45 PM",
      end: "05:30 PM",
      desc: `Changing the World of "Work-Life Harmony"`,
      attendance: false,
      video: false,
    },
  //   {
  //     id: 6,
  //     start: "09:00 AM",
  //     end: "10:15 AM",
  //     desc: "Malaysia's Incredible Covid-19 Story: A Guide to the New Normal",
  //     attendance: false,
  //     video: false,
  //   },
  //   {
  //     id: 7,
  //     start: "10:30 AM",
  //     end: "11:30 AM",
  //     desc: "Building Resilience Today for a Stronger Tomorrow",
  //     attendance: false,
  //     video: false,
  //   },
  //   {
  //     id: 8,
  //     start: "12:00 PM",
  //     end: "01:00 PM",
  //     desc: "Unlocking Stellar Employee Experience with Digital Workplaces",
  //     attendance: false,
  //     video: false,
  //   },
  //   {
  //     id: 9,
  //     start: "02:30 PM",
  //     end: "03:30 PM",
  //     desc: "Threats into Opportunities: Turning Crisis to Good Use",
  //     attendance: false,
  //     video: false,
  //   },
  //   {
  //     id: 10,
  //     start: "04:00 PM",
  //     end: "05:00 PM",
  //     desc: "The New World and Me ",
  //     attendance: false,
  //     video: false,
  //   },
  ])
  // const [agendaList, setAgendaList] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [videoId, setVideoId] = useState('')
  const [dateTabIndex, setDateTabIndex] = useState(0)

  const dateTab = [
    {
      name: '2020-12-15',
      value: '2020-12-15',
    },
    {
      name: '2020-12-16',
      value: '2020-12-16',
    },
  ]

  const videoList = {
    1: '491031405',
    2: '491095049',
    3: '491097037',
    4: '491146996',
    5: '491108475',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
  }
  // useEffect(() => {
  //   Axios({
  //     url: `${config.apiUrl}/api/v1/hall_sessions?hall_id=1`,
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
  //     },
  //   }).then((result) => {
  //     console.log(result)
  //     let withVideo = result.data.sessions.map((item) => {
  //       return {
  //         ...item,
  //         url: videoList[item.id],
  //       }
  //     })
  //     // const newAgendaList = agendaList.map((item, index) => (
  //     //   {
  //     //     // ...item,
  //     //     // attendance: result.data.sessions[index].attendance
  //     //     ...result.data.sessions[index]
  //     //   }
  //     // ));
  //     // setAgendaList(newAgendaList);
  //     setAgendaList(withVideo)
  //   })
  // }, [videoList])

  const handleVideo = (id) => {
    if (id !== '') {
      setVideoId(id)
      setIsOpen(true)
    }
  }

  return (
    <AgendaWrapper>
      {/* <header>
        {dateTab.map((item, index) => (
          <button
            className={`toggle-button ${
              index === dateTabIndex ? 'active' : ''
            }`}
            onClick={() => {
              setDateTabIndex(index)
            }}
          >
            {item.name}
          </button>
        ))}
      </header> */}
      <section className="agenda-container">
        {
          agendaList.map(agenda=>(
            <KfairAgendaItem handleVideo={handleVideo} agenda={agenda} agendaList={agendaList} setAgendaList={setAgendaList} key={agenda.id}/>
          ))
        }
        {/* {
          <div>
            {agendaList
              .filter((item) => item.date === dateTab[dateTabIndex].value)
              .map((agenda) => (
                <KfairAgendaItem
                  handleVideo={handleVideo}
                  agenda={agenda}
                  agendaList={agendaList}
                  setAgendaList={setAgendaList}
                  key={agenda.id}
                />
              ))}
          </div>
        } */}
      </section>
    </AgendaWrapper>
  )

  return (
    <AgendaWrapper>
      <header>
        <button
          className={`toggle-button ${selected ? 'active' : ''}`}
          onClick={() => setSelected(true)}
        >
          Day 1
        </button>
        <button
          className={`toggle-button ${!selected ? 'active' : ''}`}
          onClick={() => setSelected(false)}
        >
          Day 2
        </button>
      </header>
      <section className="agenda-container">
        {selected ? (
          <div>
            {agendaList
              .filter((item) => item.date === '2020-12-15')
              .map((agenda) => (
                <KfairAgendaItem
                  handleVideo={handleVideo}
                  agenda={agenda}
                  agendaList={agendaList}
                  setAgendaList={setAgendaList}
                  key={agenda.id}
                />
              ))}
          </div>
        ) : (
          <div>
            {agendaList
              .filter((item) => item.date === '2020-12-16')
              .map((agenda) => (
                <KfairAgendaItem
                  handleVideo={handleVideo}
                  agenda={agenda}
                  agendaList={agendaList}
                  setAgendaList={setAgendaList}
                  key={agenda.id}
                />
              ))}
          </div>
        )}
      </section>
      <ModalVideo
        channel="vimeo"
        autoplay
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => setIsOpen(false)}
      />
    </AgendaWrapper>
  )
}
