import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser } from '../../ducks/auth'
import { Redirect, Link } from 'react-router-dom'
import styled from 'styled-components';
import { COLORS } from '../../styles/theme'
import routes from '../../routes'

const StyledForm = styled.form`
  background: #FFF;
  color: #404040;
  padding: 20px 10px;
  border-radius: 28px;
  margin: auto;
  margin-bottom: 32px;
  width: 85%;
  text-align: center;
  p {
    font-weight: 700;
    font-size:1.2em;
  }
  input {
    width: 80%;
    background: ${COLORS.lightGray};
    border: none;
    padding: 8px 24px;
    border-radius: 25px;
    display: block;
    margin: 0px auto;
    margin-top: 20px;

    &::placeholder {
      color: ${COLORS.lightGray1};
    }
  }
  .submit-button {
    display: block;
    margin: 0.5em auto;
    width: 80%;
    margin-top: 20px;
    font-size: 1.1em;
    color: white;
    padding: 4px 30px;
    border-radius: 50px;
    letter-spacing: 1px;
    background: ${COLORS.primary};
  }
  .form-link {
    font-weight: bold;
    color: ${COLORS.lightGray1};
    cursor: pointer;
    span {
      color: #279AAC;
    }
  }
  @media screen and (min-width: 540px) {
    width: 60%;
    margin-bottom: 120px;
    font-size:120%;
    input {
      margin-top: 28px;
      margin-bottom: 12px;
      &::placeholder {
        font-size: 20px;
      }
    }
    .submit-button {
      margin-top: 32px;
      font-size: 1.3em;
    }
  }
`

const MobileLogin = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { isFetching, isAuthenticated } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(
      loginUser({
        email,
        password,
      })
    )
  }

  const onEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }

  const onPasswordInput = (e) => {
    setPassword(e.target.value)
  }

  if (isAuthenticated) {
    return <Redirect to={routes.lobby} />
  }

  return (
    <StyledForm onSubmit={handleLogin}>
      <p>Login</p>
      <input
        value={email}
        onChange={onEmailInput}
        type="email"
        placeholder="Email"
      />
      <input
        value={password}
        onChange={onPasswordInput}
        type="password"
        placeholder="Password"
      />
      <button
        disabled={!(email.length && password.length) || isFetching}
        type="submit"
        className="submit-button"
      >
        {isFetching ? 'Submitting...' : 'LOGIN'}
      </button>
      <Link to={routes.forgotPassword} className="form-link">
        Forgot password?&nbsp;
        <span>Click Here</span> 
      </Link>
    </StyledForm>
  )
}

export default MobileLogin
