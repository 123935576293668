import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import amplitude from 'amplitude-js'
import { useParams, useHistory } from 'react-router-dom'
import {
  LazyLoadImage,
  LazyLoadComponent,
} from 'react-lazy-load-image-component'
import { useDispatch, useSelector } from 'react-redux'
import { loadTawk } from '../utils/tawkToHelper'
import { getBooth, getBooths } from '../ducks/booth'

import PageWithBg from '../components/PageWithBg'
import LoadIcon from '../components/LoadIcon'
import DownloadModal from '../components/GlobalModals/DownloadModal'
import IframeModal from '../components/GlobalModals/IframeModal'
import PosterModal from '../components/GlobalModals/PosterModal'
import InfoModal from '../components/GlobalModals/InfoModal'
import VideoModal from '../components/GlobalModals/VideoModal'
import TawkModal from '../components/GlobalModals/TawkModal'
import undoIcon from '../images/navigation/undo.png'
import photoIcon from '../images/booth/photo.png'
import pdfIcon from '../images/booth/pdficon.png'
import infoIcon from '../images/booth/info.png'
import leftIcon from '../images/booth/Arrow-left.png'
import rightIcon from '../images/booth/Arrow-right.png'
import boothBg from '../images/backdrops/boothBg.jpg'

const Page = styled.div`
  overflow: hidden;
  #button-container {
    position: absolute;
    bottom: 4vh;
    left: 2%;
    .hot-buttons {
      width: 125px;
    }
  }
  .hot-buttons,
  .hot-actions {
    background: linear-gradient(#e20613, #652781);
    border-radius: 40px;
    border: 1px solid #ffad2c;
    padding: 8px 16px;
    color: #bbbdbf;
    margin: 0 8px;
    &:hover {
      box-shadow: 0px 3px 12px #ffad2c;
    }
    small {
      margin: 0;
      display: block;
      font-weight: 500;
      font-size: 70%;
    }
  }
  .booth-container {
    position: fixed;
    left: 50%;
    width: 85vw;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -75%);
    .hot-actions {
      border-radius: 50%;
      padding: 0;
      img,
      svg {
        transition: 500ms;
        width: 28px;
        height: 28px;
      }
      small {
        transition: 500ms;
        font-size: 1px;
        opacity: 0;
        color: white;
        display: none;
      }
      &:hover {
        box-shadow: 0px 3px 12px #ffad2c;
        small {
          font-size: 8px;
          opacity: 1;
          display: block;
        }
        small.extra-small {
          font-size: 7px;
        }
        img,
        svg {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  #tawk-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 40vw;
    height: 40vh;
    z-index: 9999;
  }
  .exhibitor-title {
    text-align: right;
    width: 40vw;
    position: fixed;
    bottom: 3%;
    right: 5%;
    font-weight: 600;
  }
`
const HotButton = styled.button`
  position: absolute;
  width: 55px;
  height: 55px;
  ${(props) => props.css}
`

const EMBED_DIV_ID = 'TAWK_EMBED'

export default function BoothPage({ currentUser }) {
  const [showBtn, setShowBtn] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [posterOpen, setPosterOpen] = useState(false)
  const [downloadOpen, setDownloadOpen] = useState(false)
  const [infoOpen, setInfoOpen] = useState(false)
  const [videoOpen, setVideoOpen] = useState(false)
  const [tawkOpen, setTawkOpen] = useState(false)
  const [playVideo, setPlayVideo] = useState({})

  const dispatch = useDispatch()
  const { viewBooth, booths } = useSelector((state) => state.booth)
  const { boothId } = useParams()
  const history = useHistory()

  useEffect(() => {
    dispatch(getBooth(boothId))
  }, [boothId, dispatch])

  useEffect(() => {
    if (viewBooth && viewBooth.id == boothId) {
      dispatch(getBooths(viewBooth.hall.id))
      amplitude.getInstance().logEvent(`Visit Booth`, {
        boothName: viewBooth.title,
      })
    }
  }, [boothId, currentUser.id, viewBooth])

  const handleModal = (video) => {
    setPlayVideo({
      title: 'Video',
      link: video + '?autoplay=1',
    })
    setOpen(true)
    setVideoOpen(false)
  }

  const handleDownload = () => {
    amplitude.getInstance().logEvent('Click Booth HotButton', {
      boothName: viewBooth.title,
      hotButtonType: 'pdf',
    })
    if (viewBooth.id === 13) {
      // AWS form
      window.open(
        'https://pages.awscloud.com/APAC-public-DL-eBook-Download-Machine-Learning-for-Government-Education-Healthcare-and-Nonprofits-2020-learn.html?sc_channel=em&sc_campaign=%20APAC_WWPS_LN_ebook-download-machine-learning-for-government-education-healthcare-and-nonprofits_2020106_7010z000001M0mW&sc_medium=em_&sc_content=REG_webinar_wwps&sc_detail=ebookdownloadmachinelearning&sc_geo=apac&sc_country=mult&sc_outcome=reg&trk=lp_apac_crosspromo_EPFMY',
        '_blank'
      )
    } else {
      setDownloadOpen(!downloadOpen)
    }
  }

  const handleVideoModal = () => {
    amplitude.getInstance().logEvent('Click Booth HotButton', {
      boothName: viewBooth.title,
      hotButtonType: 'video',
    })
    if (viewBooth.video_urls.length > 1) {
      setVideoOpen(!videoOpen)
    } else {
      if (viewBooth.id === 22) {
        setPlayVideo({
          title: 'Video',
          link: viewBooth.video_urls[0] + window.location.hostname,
        })
      } else {
        setPlayVideo({
          title: 'Video',
          link: viewBooth.video_urls[0],
        })
      }
      setOpen(true)
      setVideoOpen(false)
    }
  }
  const handlePoster = () => {
    amplitude.getInstance().logEvent('Click Booth HotButton', {
      boothName: viewBooth.title,
      hotButtonType: 'poster',
    })
    setPosterOpen(!posterOpen)
  }
  const handleInfo = () => {
    amplitude.getInstance().logEvent('Click Booth HotButton', {
      boothName: viewBooth.title,
      hotButtonType: 'info',
    })
    setInfoOpen(!infoOpen)
  }

  const openTawk = () => {
    if (viewBooth) {
      amplitude.getInstance().logEvent('Click Booth HotButton', {
        boothName: viewBooth.title,
        hotButtonType: 'chat',
      })
      const { chat_link } = viewBooth
      loadTawk(currentUser, chat_link, EMBED_DIV_ID)
      setTawkOpen(!tawkOpen)
    }
  }

  const handleConsultation = () => {
    amplitude.getInstance().logEvent('Click Booth HotButton', {
      boothName: viewBooth.title,
      hotButtonType: 'consultation',
    })
    window.open('https://jiggee.zoom.us/my/hybridhub3', '_blank')
  }
  const navigatePrev = () => {
    setShowBtn(false)
    const navigateTo = booths.filter(booth=>booth.order===viewBooth.order-1)[0].id
    history.push(`/booths/${navigateTo}`)
  }
  const navigateNext = () => {
    setShowBtn(false)
    const navigateTo = booths.filter(booth=>booth.order===viewBooth.order+1)[0].id
    history.push(`/booths/${navigateTo}`)
  }

  return (
    <Page>
      <PageWithBg bgImg={boothBg}>
        {!showBtn && <LoadIcon />}
        {viewBooth && (
          <LazyLoadComponent>
            <div className="booth-container">
              <LazyLoadImage
                effect="black-and-white"
                afterLoad={() => setShowBtn(true)}
                className="booth-img"
                src={viewBooth.background_image.url}
                width="100%"
                alt="booth image"
              />
              {showBtn && (
                <>
                  <HotButton
                    css={viewBooth.btnPos.vid}
                    className="hot-actions"
                    onClick={handleVideoModal}
                  >
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#ffffff"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <polygon points="10 8 16 12 10 16 10 8"></polygon>
                    </svg>
                    <small>
                      Video
                      <br />
                      Gallery
                    </small>
                  </HotButton>
                  <HotButton
                    css={viewBooth.btnPos.pdf}
                    className="hot-actions"
                    onClick={handleDownload}
                  >
                    <img src={pdfIcon} width="26" alt="Nav" />
                    <small>
                      PDF
                      <br />
                      Gallery
                    </small>
                  </HotButton>
                  <HotButton
                    css={viewBooth.btnPos.image}
                    className="hot-actions"
                    onClick={handlePoster}
                  >
                    <img src={photoIcon} width="26" alt="Nav" />
                    <small>
                      Image
                      <br />
                      Gallery
                    </small>
                  </HotButton>
                  <HotButton
                    css={viewBooth.btnPos.info}
                    className="hot-actions"
                    onClick={handleInfo}
                  >
                    <img src={infoIcon} width="26" alt="Nav" />
                    <small>
                      Exhibitor
                      <br />
                      Info
                    </small>
                  </HotButton>
                  <HotButton
                    css={viewBooth.btnPos.chat}
                    className="hot-actions"
                    onClick={openTawk}
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                        fill="#ffffff"
                        d="M20,2 L4,2 C2.9,2 2,2.9 2,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M19,16 L6,16 L4,18 L4,5 C4,4.45 4.45,4 5,4 L19,4 C19.55,4 20,4.45 20,5 L20,15 C20,15.55 19.55,16 19,16 Z"
                      ></path>
                    </svg>
                    <small>
                      Talk
                      <br />
                      To Us
                    </small>
                  </HotButton>
                  {
                    // JRAP zoom call
                    viewBooth.id === 5 && (
                      <>
                        <HotButton
                          css={viewBooth.btnPos.zoom}
                          className="hot-actions"
                          onClick={handleConsultation}
                        >
                          <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                          >
                            <path
                              fill="none"
                              d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
                            />
                            <path
                              fill="#ffffff"
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M38.087 15.9353L31.3043 20.884V27.135L38.087 32.0837C38.5669 32.4503 39.1304 32.5636 39.1304 31.5628V16.4562C39.1304 15.5638 38.6782 15.4517 38.087 15.9353ZM8.34782 27.7567V16.4873C8.34782 16.0263 8.72491 15.6522 9.19007 15.6522H25.6254C28.1845 15.6522 30.2609 17.7077 30.2609 20.2433V31.5127C30.2609 31.9737 29.8838 32.3478 29.4186 32.3478H12.9833C10.4242 32.3478 8.34782 30.2923 8.34782 27.7567Z"
                            />
                          </svg>
                          <small className="extra-small">Consultation</small>
                        </HotButton>
                      </>
                    )
                  }
                </>
              )}
            </div>
            <div id="button-container">
              <button
                className="hot-buttons"
                onClick={() =>
                  history.push(`/exhibition-hall-${viewBooth.id <= 12 ? 1 : 2}`)
                }
              >
                <img src={undoIcon} width="30" alt="Nav" />
                <small>Return to Hall {viewBooth.id <= 12 ? `A` : `B`}</small>
              </button>
              {viewBooth.order !== 1 && (
                <button className="hot-buttons" onClick={navigatePrev}>
                  <img src={leftIcon} width="15" alt="Nav" />
                  <small>Previous Booth</small>
                </button>
              )}
              {viewBooth.order !== booths.length && (
                <button className="hot-buttons" onClick={navigateNext}>
                  <img src={rightIcon} width="15" alt="Nav" />
                  <small>Next Booth</small>
                </button>
              )}
            </div>
            <h4 className="exhibitor-title">{viewBooth.title}</h4>
            <VideoModal
              videos={viewBooth.video_urls}
              setVideoOpen={setVideoOpen}
              videoOpen={videoOpen}
              handleModal={handleModal}
            />
            <IframeModal
              iframeOpen={isOpen}
              setIframeOpen={setOpen}
              iframe={playVideo}
            />
            <DownloadModal
              files={viewBooth.documents}
              setDownloadOpen={setDownloadOpen}
              downloadOpen={downloadOpen}
            />
            <PosterModal
              images={viewBooth.images}
              posterOpen={posterOpen}
              setPosterOpen={setPosterOpen}
            />
            <InfoModal
              title={viewBooth.title}
              description={viewBooth.description}
              content={viewBooth.content}
              agents={viewBooth.booth_agents}
              infoOpen={infoOpen}
              setInfoOpen={setInfoOpen}
            />
            <TawkModal
              embedId={EMBED_DIV_ID}
              tawkOpen={tawkOpen}
              setTawkOpen={setTawkOpen}
            />
          </LazyLoadComponent>
        )}
      </PageWithBg>
    </Page>
  )
}
