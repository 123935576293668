import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import amplitude from 'amplitude-js'
import routes from '../../routes'
import { toggleMute } from '../../ducks/layout'
import MobileNotice from './MobileNotice'

import logo from '../../images/mcp_logo.png'
import lobby from '../../images/mobile/lobby.jpg'
// import living from '../../images/mobile/living.png'
import meeting from '../../images/mobile/meeting.png'
import mute from '../../images/mobile/mute.png'
import expo from '../../images/mobile/expo.png'
import gallerium from '../../images/mobile/gallerium.png'
import networking from '../../images/mobile/networking.png'
import config from '../../config'
import { COLORS } from '../../styles/theme'

const MobileLobby = ({ currentUser }) => {
  const dispatch = useDispatch()
  const { muted } = useSelector((state) => state.layout)

  const toggleAudio = () => {
    amplitude.getInstance().logEvent('Click Mute button')
    dispatch(toggleMute())
  }

  useEffect(() => {
    amplitude.getInstance().logEvent('Visit Lobby')
  }, [currentUser.id])

  return (
    <Page>
      <img className="logo" src={logo} alt="logo" />
      <MobileNotice />
      <div className="menu">
        <p className="title">Virtual Halls</p>
        <div className="mute-button" onClick={toggleAudio}>
          <img className="mute-icon" src={mute} />
          <p className="mute-label">{muted ? 'Unmute' : 'Mute'}</p>
        </div>
        <div className="list-wrapper">
          <div className="item-box">
            <div className="icon-wrapper">
              <img className="meeting" src={meeting} alt="icon" />
            </div>
            <p className="item-label">Conference Hall</p>
            <Link to={routes.auditorium1} className="item-button">
              Enter
            </Link>
          </div>

          {/* {currentUser.role === 'manager' && (
            <div className="item-box">
              <div className="icon-wrapper">
                <img className="meeting2" src={emc2} />
              </div>
              <p className="item-label">MC20 Conference Hall</p>
              <Link to={routes.auditorium2} className="item-button">
                Enter
              </Link>
            </div>
          )} */}

          <div className="item-box">
            <div className="icon-wrapper">
              <img className="expo" src={expo} alt="icon"/>
            </div>
            <p className="item-label">Exhibition Hall A</p>
            <Link to={routes.exhibition1} className="item-button">
              Enter
            </Link>
          </div>

          <div className="item-box">
            <div className="icon-wrapper">
              <img className="expo" src={expo} alt="icon"/>
            </div>
            <p className="item-label">Exhibition Hall B</p>
            <Link to={routes.exhibition2} className="item-button">
              Enter
            </Link>
          </div>

          <div className="item-box">
            <div className="icon-wrapper">
              <img className="networking" src={networking} alt="icon" />
            </div>
            <p className="item-label">Networking Lounge</p>
            <Link to={routes.networking} className="item-button">
              Enter
            </Link>
          </div>

          <div className="item-box">
            <div className="icon-wrapper">
              <img className="gallerium" src={gallerium} alt="icon"/>
            </div>
            <p className="item-label">E Poster Gallery</p>
            <Link to={routes.gallerium} className="item-button">
              Enter
            </Link>
          </div>
        </div>
      </div>
    </Page>
  )
}

const Page = styled.div`
  /*background: #0c1750;*/
  background: url(${config.assets.lobby.portrait});
  background-size: cover;
  background-position: top center;

  height: calc(100% - 60px);
  width: 100vw;
  overflow: hidden;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .logo {
    width: 70%;
    display: block;
    margin: 36px auto;
  }


  .living {
    width: 150px;
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translateX(-50%);
  }

  /* .video-wrapper {
    width: 100%;

    position: absolute;
    bottom: 230px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;

    padding-bottom: 56.25%;
    height: 0;

    background: url(${lobby});
    background-size: cover;
    background-position: 50% 50%;

    iframe {
      position: absolute;
    }
  } */

  .menu {
    width: 100%;
    height: 250px;
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    background: white;
    border-radius: 20px 20px 0px 0px;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .mute-button {
    width: 80px;
    height: 22px;
    position: absolute;
    top: 22px;
    right: 20px;
  }

  .mute-icon {
    height: 15px;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  .mute-label {
    font-size: 12px;
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
  }

  .list-wrapper {
    width: 100%;
    height: 180px;
    position: absolute;
    top: 50px;
    left: 0;
    overflow-x: scroll;
    display: flex;
    align-items: center;
  }

  .item-box {
    width: 130px;
    height: 160px;
    margin-left: 20px;
    border: solid 3px ${COLORS.primary};
    border-radius: 10px;
    background: white;
    flex-shrink: 0;
    box-sizing: border-box;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .icon-wrapper {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item-label {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    color: #272727;
    margin: 0px;
  }

  .item-button {
    background-color: #270949;
    color: white;
    border: none;
    border-radius: 30px;
    font-size: 12px;
    font-weight: bold;
    width: 90px;
    height: 25px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .meeting1 {
    width: 135%;
    transform: translateY(15%);
  }

  .meeting2 {
    width: 85%;
  }

  .meeting {
    width: 150%;
  }

  .expo {
    width: 170%;
  }

  .gallerium {
    width: 140%;
  }

  .networking {
    width: 110%;
  }

  @media screen and (min-width: 540px) {
    /* .video-wrapper {
      width: 500px;
      height: 281.25px;
      padding-bottom: 0;

      position: absolute;
      top: 30%;
      bottom: 230px;
      left: 50%;
      transform: translateX(-50%);
    } */
    .menu {
      height: 275px;
    }
    .list-wrapper {
      top: 65px;
    }
    .logo {
      width: 50%;
      margin: 100px auto;
    }

  }
`

export default MobileLobby
