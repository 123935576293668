import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import amplitude from 'amplitude-js'

import search from '../../images/mobile/search.png'
import linkIcon from '../../images/mobile/link.png'

import routes from '../../routes'
import { useDispatch, useSelector } from 'react-redux'
import { getBooths } from '../../ducks/booth'

const MobileExpoDirectory = (props) => {
  const { hallNumber, currentUser } = props
  const dispatch = useDispatch()
  const { booths } = useSelector((state) => state.booth)

  const [boothList, setBoothList] = useState(booths)
  const [searchInput, setSearchInput] = useState('')

  useEffect(() => {
    dispatch(getBooths(hallNumber))
  }, [hallNumber, dispatch])

  useEffect(() => {
    if (searchInput !== '') {
      const newBoothList = booths.filter((item) =>
        new RegExp(searchInput.toLowerCase()).test(item.title.toLowerCase())
      )
      setBoothList(newBoothList)
    } else {
      setBoothList(booths)
    }
  }, [booths, searchInput])

  useEffect(() => {
    amplitude.getInstance().logEvent(`Visit Exhibition Hall`, {
      hallNumber: hallNumber,
    })
  }, [currentUser.id, hallNumber])

  const getHallName = (hallNumber) => {
    if (hallNumber === 1) {
      return 'A'
    } else if (hallNumber === 2) {
      return 'B'
    }
  }

  const onChangeSearchInput = (e) => {
    setSearchInput(e.target.value)
  }

  return (
    <div>
      <Page>
        <div className="outer-wrapper">
          <div className="container">
            <div className="title-container">
              <p className="title">Hall {getHallName(hallNumber)}</p>
              <Link className="back-button" to={routes.lobby}>
                Back
              </Link>
            </div>
            <div className="search-wrapper">
              <input
                value={searchInput}
                onChange={onChangeSearchInput}
                type="text"
                placeholder="Search exhibitor"
                className="search-input"
              />
              <img src={search} className="search-icon" />
            </div>

            {boothList.map((item, index) => (
              <Link to={`/booths/${item.id}`} key={index}>
                <div className="item-wrapper">
                  <p className="item-title">{item.title}</p>
                  <img src={linkIcon} className="item-icon" />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </Page>
    </div>
  )
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  a {
    color: black;
  }

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 90%;
    margin: auto;
  }

  .title-container {
    margin: 20px 0px 10px 0px;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    width: 70%;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: black;
    margin: 0;
  }

  .back-button {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
  }

  .search-wrapper {
    width: 100%;
    height: 40px;
    border: solid 3px #662283;
    border-radius: 30px;
    padding: 0px 10px 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .search-input {
    width: 70%;
    font-size: 14px;
    border: none;
  }

  .search-icon {
    height: 20px;
  }

  .item-wrapper {
    width: 100%;
    padding: 0px 20px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  .item-title {
    width: 80%;
    font-size: 15px;
    margin: 0;
  }

  .item-icon {
    height: 20px;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileExpoDirectory
