import React, { useRef, useState } from 'react'
import { Modal, Row, Col, Carousel, Form } from 'react-bootstrap'
import amplitude from 'amplitude-js';
import styled from 'styled-components'
import Avatar from 'react-avatar'
import arrow from '../../images/navigation/up.png'
import about from '../../images/event/about.jpg'
import agendaImage from '../../images/event/agenda.jpg'
import speaker from '../../images/event/speaker-info.jpg'
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import { COLORS } from '../../styles/theme'
import aboutIcon from '../../images/event/about.png'
import speakerIcon from '../../images/event/speaker.png'
import agendaIcon from '../../images/event/agenda.png'
import Axios from 'axios'
import { toast } from 'react-toastify'
import config from '../../config'
import { useDispatch } from 'react-redux'
import { refreshUser } from '../../ducks/auth'

const StyledModal = styled(Modal)`
  text-align: center;
  color: ${COLORS.primary};
  /* mix-blend-mode: hard-light; */

  .modal-dialog {
    min-width: 75%;
  }
  .modal-content {
    background-color: rgba(255, 255, 255, 0.9);
    /* background-image: linear-gradient(rgba(113, 3, 11, 1), rgba(39, 13, 72, 1)); */
    /* opacity: 0.94; */
    border: none;
    border-radius: 0;
    padding: 24px 36px;
    margin-bottom: 24px;

    .modal-header {
      color: #FFAD2C;
      border: none;
      text-align: left;
      .modal-title {
        font-size: 36px;
        font-weight: bold;
        font-style: italic;
      }
      button {
        font-size: 36px;
        opacity: 1;
        color: rgba(0, 0, 0, 0.55);
      }
    }
    .header-section {
      margin-top: 16px;
      #profile {
        .profile-image {
          border-radius: 50%;
          width: 98px;
          height: 98px;
          .avatar {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }
      .edit-card {
        text-align: left;
        background: white;
        border: 3px solid #FFAD2C;
        border-radius: 16px;
        padding: 16px;
        .form-label {
          width: 20%;
        }
        input {
          padding: 4px 16px;
          width: 80%;
        }
        .fileinput {
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;
        }
        .fileinput + label {
          cursor: pointer;
        }
        .upload-label {
          align-self: center;
          margin: 0;
          background: ${COLORS.primary};
          color: #FFAD2C;
          border-radius: 32px;
          font-weight: 600;
          padding: 8px 26px;
        }
        .form-btn {
          button {
            margin-left: 16px;
            color: ${COLORS.primary};
            font-weight: 600;
            border-radius: 16px;
            padding: 2px 8px;
            border: 1px solid ${COLORS.primary};
          }
          .upload-btn:disabled {
            color: rgba(0, 0, 0, 0.5);
            border: 1px solid rgba(0, 0, 0, 0.5);
          }
        }
        .image-name {
          color: ${COLORS.primary};
          width: 265px;
          border: 1px solid #FFAD2C;
          border-radius: 12px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-style: italic;
          padding: 0px 12px;
          font-size: 12px;
          display:flex;
          align-items: center;
        }
      }
      button {
        border: none;
        background: transparent;
        color: ${COLORS.primary};
        display: block;
      }
      .navigation {
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        button {
          width: 150px;
          background: #002060;
          border: 2px solid #FFAD2C;
          border-radius: 32px;
          padding: 6px 16px;
          display: flex;
          color: white;
          justify-content: space-around;
          align-items: center;
          h5 {
            margin: 0;
          }
          &:hover {
            background: #FFAD2C;
          }
        }
      }
    }
    .divider {
      background: linear-gradient(270deg, #FE1E9A, #254DDE);
      height: 4.5px;
      margin: 32px 0;
    }
  }
  .back-top {
    background: #002060;
    border: none;
    padding: 0;
    border-radius: 12px;
    position: absolute;
    bottom: 0;
    right: 0;
    svg {
      width:50;
      height:50;
    }
  }
  .modal-body {
    padding-bottom: 36px;
  }
  .agenda {
    button {
      margin: 16px;
      background: #002060;
      border: 2px solid #FFAD2C;
      font-weight: 500;
      border-radius: 32px;
      padding: 4px 12px;
      color: white;
      font-size: 24px;
    }
    .active {
      font-style: italic;
      color: #FFAD2C;
      text-decoration: underline;
    }
  }
`

export default function ProgrammeModal({ showModal, hideSchedule, currentUser }) {
  const speakerSec = useRef()
  const aboutSec = useRef()
  const agendaSec = useRef()
  const startModal = useRef()

  const dispatch = useDispatch()
  const [ agenda, setAgenda ] = useState(true)
  const [ showEdit, setShowEdit ] = useState(false)
  const [ image, setImage ] = useState(null)
  const [ loading, setLoading ] = useState(false)

  const toggleEdit = () => {
    setImage(null)
    setShowEdit(!showEdit)
  }
  
  const scrollTop = (ref) =>{
    ref.current.scrollIntoView({ behavior: 'smooth' })
  };
  
  const handleFileInput = (e) => setImage(e.target.files[0])
  const handleUploadImage = (e) => {
    amplitude.getInstance().logEvent('Upload profile image');
    e.preventDefault()
    setLoading(true)
    let formData = new FormData();
    formData.append("image", image);
    Axios.post(`${config.apiUrl}/api/v1/users/upload`, formData, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt_token')}`
      }
    })
      .then((resp) => {
        toast('Profile image uploaded.')
        setImage(null)
        dispatch(refreshUser())
        toggleEdit()
        setLoading(false)
      })
      .catch((err) => {
        toast('Something went wrong, pls try again!')
        toggleEdit()
        setLoading(false)
      })
  }
  
  return (
    <StyledModal
      show={showModal}
      onHide={hideSchedule}
      size="xl"
      aria-labelledby="menu-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>MENU</Modal.Title>
        <span ref={startModal}></span>
      </Modal.Header>
      <Modal.Body>
        <Row className="header-section" noGutters>
          <Col md={12} lg={6} id="profile" className="d-flex align-items-start">
            <div className="profile-image">
              {
                currentUser.avatar ?
                <img src={currentUser.avatar.url} width="98px" className="avatar" alt="profile-image" />
                :
                <Avatar name={currentUser.fullName} size="98" round />
              }
            </div>
            <div className="ml-2 text-left">
              <h4>{currentUser.fullName}</h4>
              <button onClick={toggleEdit}>
                <small>Upload Your Photo</small>
              </button>
            </div>
          </Col>
          <Col md={12} lg={6} className="mt-md-5 my-lg-0 pr-lg-3">
          {
            showEdit ?
            <Form className="edit-card" onSubmit={handleUploadImage}>
              <Form.Group className="d-flex justify-content-between">
                <input id="image-input" type="file" className="fileinput" onChange={handleFileInput} />
                <label for="image-input" className="upload-label">Browse Image</label>
                {image && (
                  <div className="image-name">
                    <span>{image.name}</span>
                  </div>
                )}
              </Form.Group>
              {/* <Form.Group>
                <Form.Label>Name</Form.Label>
                <input name="fullName" onChange={handleInput} value={userDetails.fullName}/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <input name="email" onChange={handleInput} value={userDetails.email}/>
              </Form.Group> */}
              <div className="form-btn d-flex justify-content-end" >
                <button 
                  className="upload-btn"
                  type="submit"
                  disabled={ !image || loading }
                >
                  Upload
                </button>
                <button onClick={toggleEdit}>
                  Cancel
                </button>
              </div>
            </Form>
            :
            <div className="navigation">
              <button onClick={()=>scrollTop(aboutSec)}>
                <img src={aboutIcon} width="24" alt="nav"/>
                <h5>About</h5>
              </button>
              <button onClick={()=>scrollTop(agendaSec)}>
                <img src={agendaIcon} width="24" alt="nav"/>
                <h5>Agenda</h5>
              </button>
              <button onClick={()=>scrollTop(speakerSec)}>
                <img src={speakerIcon} width="24" alt="nav"/>
                <h5>Speakers</h5>
              </button>
            </div>
          }
          </Col>
        </Row>
        <div className="divider"></div>
        <div>
          <div ref={aboutSec}></div>
          <LazyLoadImage effect="black-and-white" src={about} width="90%" alt="about event" />
        </div>
        <div className="divider"></div>
        <span ref={agendaSec}></span>
        <div>
          <LazyLoadImage effect="black-and-white" src={agendaImage} width="90%" alt="agenda" />

          {/* <div className="agenda">
            <button className={`${agenda && 'active'}`} onClick={()=>setAgenda(true)}>Day 1 : Dec 15</button>
            <button className={`${!agenda && 'active'}`} onClick={()=>setAgenda(false)}>Day 2 : Dec 16</button>
          </div>
          {
            agenda ?
            <LazyLoadImage effect="black-and-white" src={day1} width="90%" alt="agenda day 1" />
            :
            <LazyLoadImage effect="black-and-white" src={day2} width="90%" alt="agenda day 2" />
          } */}
        </div>
        <div className="divider"></div>
        <div className="container">
          <div ref={speakerSec}></div>
          <LazyLoadImage effect="black-and-white" src={speaker} width="90%" alt="speaker-info" />
          {/* <LazyLoadComponent>
            <Carousel>
              {
                [speaker1,speaker2,speaker3,speaker4,speaker5,speaker6].map((image, index)=>(
                  <Carousel.Item key={`speakerinfo-${index}`} >
                    <img 
                      src={image} 
                      className="d-block mx-auto"
                      width="80%" 
                      alt={`speakerinfo-${index}`}
                    />
                  </Carousel.Item>
                ))
              }
            </Carousel>
          </LazyLoadComponent> */}
        </div>
        <button onClick={()=>scrollTop(startModal)} className="back-top">
          <svg width="45" height="45" viewBox="0 0 45 45">
            <g transform="translate(-1767 -3747)">
              <g transform="translate(120 -2428)">
                <path d="M22.175,9.358a1.43,1.43,0,0,0-1.43-1.43H5.063l5.813-5.42A1.43,1.43,0,1,0,8.923.421L.455,8.311a1.43,1.43,0,0,0,0,2.091L8.923,18.3A1.43,1.43,0,1,0,10.875,16.2L5.063,10.788H20.746A1.43,1.43,0,0,0,22.175,9.358Z" transform="translate(1678.681 6187) rotate(90)" fill="#ffad2c"/>
              </g>
            </g>
          </svg>
        </button>
      </Modal.Body>
    </StyledModal>
  )
}
