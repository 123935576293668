import React from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';


import routes from '../../routes';
import config from '../../config';

import MobileLanding from './MobileLanding';
import MobileLogin from './MobileLogin';
import MobileForgotPassword from './MobileForgotPassword';
import logo from "../../images/mcp_logo.png"

const MobileEntrance = () => {
  return (
    <Page>
      <div id="content">
        <img width="60%" className="mx-auto d-block" src={logo} alt="logo" />
        
        <Switch>
          <Route exact path={routes.root}>
            <MobileLanding />
          </Route>

          <Route exact path={routes.login}>
            <MobileLogin />
          </Route>

          <Route exact path={routes.forgotPassword}>
            <MobileForgotPassword />
          </Route>

        </Switch>
      </div>
    </Page>
  );
}

const Page = styled.div`
  background: url(${config.assets.entrance.portrait});
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  padding-top: 3rem;

  #content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`

export default MobileEntrance;