export default {
  root: '/',
  resetPassword: '/register/create-password',
  forgotPassword: '/register/forgot-password',
  login: '/login',

  notifications: '/notifications',
  menu: '/menu',
  help: '/help',

  lobby: '/lobby',

  networking: '/networking-lounge',
  cloudflix: '/highlights',

  auditorium1: '/auditorium-hall',
  auditorium2: '/mc20-auditorium',

  exhibition1: '/exhibition-hall-1',
  exhibition2: '/exhibition-hall-2',

  gallerium: '/gallerium',
}
