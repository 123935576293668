import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import config from '../config'
import { COLORS, SIZES } from '../styles/theme'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser } from '../ducks/auth'
import { Redirect } from 'react-router-dom'
import ForgetPassword from '../components/ForgetPassword'
import routes from '../routes'

const LoginBox = styled.section`
   position: fixed;
  bottom: 5vh;
  right: 0vw;
  padding: 4vw;
  padding-bottom: 0;
  max-height: 100%;
  text-align: center;
  color: white;

  .action-text {
    font-weight: bold;
    color: #279AAC;
    cursor: pointer;
  }

  form {
    background: #FFF;
    color: #404040;
    padding: 30px 10px;
    border-radius: 28px;
    margin: auto;
    margin-top: 30px;
    max-width: 375px;

    input {
      width: 80%;
      background: ${COLORS.lightGray};
      border: none;
      padding: 12px 28px;
      border-radius: 20px;
      margin-top: 20px;

      &::placeholder {
        color: ${COLORS.lightGray1};
      }
    }

    .forget-pass {
      width: 80%;
      color: ${COLORS.primaryTrans};
      margin-top: 10px;
      font-size: 0.9em;
    }

    .login-btn {
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin: auto;
      margin-top: 2.5em;
      margin-bottom: 0.5em;
      font-size: 1.2em;
      color: #FFFFFF;
      padding: 6px 30px;
      border-radius: 20px;
      letter-spacing: 1px;
      background: #002060;
      div {
        line-height: 32px;
      }
      div.arrow {
        line-height: 24px;
        font-size: 1.5em;
        align-self: flex-end;
      }
    }
    .title {
      font-weight: 500;
      font-size: 20px;
      margin: 0;
      color: #404040;
    }
  }

  ${SIZES.mobile} {
    position: static;
    padding: 20% 10px;
    min-height: 100vh;
    max-height: auto;

    form {
      border-radius: 10px;
    }
  }
`

export default function LoginPage() {
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')

  const { isFetching, isAuthenticated } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(
      loginUser({
        email,
        password,
      })
    )
  }
  const onPasswordInput = (e) => {
    setPassword(e.target.value)
  }

  const onEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }

  useEffect(() => {
    document.title = `${config.eventName} | Login`
  }, [])

  if (isAuthenticated) {
    return <Redirect to={routes.lobby} />
  } else {
    return (
      <LoginBox>
        <>
          <form onSubmit={handleLogin}>
            <p className="title">Login</p>
            <input
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={onEmailInput}
            />
            <input
              type="password"
              value={password}
              placeholder="Password"
              onChange={onPasswordInput}
            />
            <input
              disabled={!(email.length && password.length) || isFetching}
              type="submit"
              value={isFetching ? 'LOGGING IN...' : 'LOGIN'}
              className="btn login-btn"
            />

            <ForgetPassword>
              <span className="text-secondary font-weight-bold">Forgot Password?&nbsp;</span>
              <span className="action-text">Click Here</span>
            </ForgetPassword>

          </form>
        </>
      </LoginBox>
    )
  }
}
