import React, { useState } from 'react'
import styled from 'styled-components'
import pinIcon from '../images/icons/pinIcon.png'
import checkIn from '../images/icons/checkin.png'
import replay from '../images/icons/replay.png'
import block from '../images/icons/replay-NA.png'
import { toast } from 'react-toastify'
import Axios from 'axios'
import config from '../config'

const StyledAgenda = styled.div`
  .agenda-item {
    .end {
      color: #ffad2c;
    }
    .attend,
    .replay {
      border: none;
      background: transparent;
      height: 30px;
      width: 50px;
      margin: 0;
      p.load {
        margin: 0;
        font-weight: 500;
        color: #ffad2c;
        font-size: 8px;
      }
      img {
        height: 100%;
      }
      .icon-label {
        text-align: center;
        color: white;
        font-size: 1px;
        opacity: 0;
      }
      &:hover {
        img.pending {
          transform: scale(0.6);
        }
        .icon-label {
          font-size: 6.5px;
          opacity: 1;
        }
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`

export default function KfairAgendaItem({
  handleVideo,
  agenda,
  agendaList,
  setAgendaList,
  currentUser,
}) {
  const [isLoading, setIsLoading] = useState(false)

  const takeAttendance = (id) => {
    if (agendaList.filter((item) => item.id === id)[0].attendance === true)
      return
    let newList = agendaList.map((item)=>(
      {
        ...item,
        attendance: item.id === id ? true : item.attendance
      }
    ))
    setAgendaList(newList);
    // setIsLoading(true)

    // Axios({
    //   method: 'post',
    //   url: `${config.apiUrl}/api/v1/hall_sessions/${id}/check_in`,
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
    //   },
    // })
    //   .then((response) => {
    //     setIsLoading(false)
    //     toast(`Check-in successful`)
    //     const newAgendaList = agendaList.map((item, index) => ({
    //       ...item,
    //       attendance: item.id === id ? true : item.attendance,
    //     }))
    //     setAgendaList(newAgendaList)
    //   })
    //   .catch((err) => {
    //     setIsLoading(false)
    //     // console.error(err.response.data.error);
    //     if (
    //       err &&
    //       err.response &&
    //       err.response.data &&
    //       err.response.data.error
    //     ) {
    //       toast(err.response.data.error)
    //     } else {
    //       toast('Check-in failed. Something went wrong')
    //     }
    //   })
  }
  const replayVideo = (url) => {
    if (url === '') {
      toast('Video can only be replayed after the event has ended')
    } else {
      handleVideo(url)
    }
  }

  return (
    <StyledAgenda>
      <div className="d-flex agenda-item my-4">
        <div className="w-25">
          <small className="d-block start">{agenda.start}</small>
          <small className="d-block end">{agenda.end}</small>
        </div>
        <small className="d-block w-50">{agenda.desc}</small>
        <div className="w-25 buttons-container">
          <button
            className="attend"
            onClick={() => takeAttendance(agenda.id)}
            disabled={isLoading || agenda.attendance}
          >
            {isLoading ? (
              <p className="load">Loading</p>
            ) : (
              <>
                <img
                  src={agenda.attendance ? checkIn : pinIcon}
                  className={agenda.attendance ? `checked` : `pending`}
                  alt="attendance-button"
                />
                <small
                  className={`icon-label ${
                    agenda.attendance ? `d-none` : `d-block`
                  }`}
                >
                  Check-in
                </small>
              </>
            )}
          </button>
          <button className="replay" onClick={() => replayVideo(agenda.url)}>
            <img
              src={agenda.url ? replay : block}
              className={agenda.url ? `pending` : `checked`}
              alt="replay-button"
            />
            <small
              className={`icon-label ${agenda.url ? `d-block` : `d-none`}`}
            >
              Replay
            </small>
          </button>
        </div>
      </div>
    </StyledAgenda>
  )
}
