import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyCT9Y4ophgysD6jJIDJGdTr5MRua_0mdy0',
  authDomain: 'mcp-demo-303407.firebaseapp.com',
  projectId: 'mcp-demo-303407',
  storageBucket: 'mcp-demo-303407.appspot.com',
  messagingSenderId: '120394918758',
  appId: '1:120394918758:web:3cf4a7dc8fe3d37fb4f6a7',
  // measurementId: 'G-MYCN9XLCDX',
}

firebase.initializeApp(firebaseConfig)

const firebaseDb = firebase.database()
const firebaseAuth = firebase.auth()

const ChatDb = firebaseDb.ref('chat')
const ChatKfairDb = firebaseDb.ref('chatKfair')
const ChatMcDb = firebaseDb.ref('chatMc')
const NoticeDb = firebaseDb.ref('notice')
const NotificationDb = firebaseDb.ref('pushNotifications')

export {
  firebaseDb,
  firebaseAuth,
  ChatDb,
  ChatKfairDb,
  ChatMcDb,
  NoticeDb,
  NotificationDb,
}
