import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import amplitude from 'amplitude-js'

import config from '../../config'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { refreshUser } from '../../ducks/auth'

const MobileEditProfile = ({ currentUser }) => {
  const history = useHistory()
  const [image, setImage] = useState(null)
  const [loading, setLoading] = useState(null)
  const dispatch = useDispatch()

  const onCancel = () => {
    history.push('/menu')
  }

  const handleFileInput = (e) => {
    setImage(e.target.files[0])
  }

  const handleUploadImage = (e) => {
    amplitude.getInstance().logEvent('Upload profile image')
    e.preventDefault()
    let formData = new FormData()
    setLoading(true)
    formData.append('image', image)
    Axios.post(`${config.apiUrl}/api/v1/users/upload`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      },
    })
      .then((resp) => {
        toast('Profile image uploaded.')
        setImage(null)
        dispatch(refreshUser())
        // update currentUser state at the same time
        setLoading(false)
        history.push('/menu')
      })
      .catch((err) => {
        console.error(err)
        console.log(err)
        toast('Something went wrong, pls try again!')
        setLoading(false)
      })
  }

  return (
    <div>
      <Page>
        <div className="outer-wrapper">
          <div className="container">
            <div className="title-container">
              <p className="title">Upload photo</p>
              <Link className="back-button" to="/menu">
                Back
              </Link>
            </div>

            <div className="profile-container">
              <div className="profile-image">
                {currentUser.avatar && (
                  <img src={currentUser.avatar.url} className="avatar" />
                )}
              </div>
              <div className="profile-info-container">
                <p className="profile-info-name">{currentUser.fullName}</p>
              </div>
            </div>

            <Form onSubmit={handleUploadImage}>
              <Form.Group>
                <Form.File onChange={handleFileInput} />
              </Form.Group>
              <div className="buttons-container">
                <button
                  onClick={onCancel}
                  type="button"
                  className="button"
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="button"
                  disabled={!image || loading}
                >
                  {loading ? 'Saving...' : 'Save'}
                </button>
              </div>
            </Form>

            {/*           <form> */}
            {/*              */}
            {/*             <p className='input-label'>Name</p> */}
            {/*             <input type='text' className='input'/> */}
            {/*  */}
            {/*             <p className='input-label'>Company Name</p> */}
            {/*             <input type='text' className='input'/> */}
            {/*  */}
            {/*             <p className='input-label'>Mobile No.</p> */}
            {/*             <input type='text' className='input'/> */}
            {/*  */}
            {/*             <p className='input-label'>Email</p> */}
            {/*             <input type='text' className='input'/> */}
            {/*  */}
            {/*             <div className='buttons-container'> */}
            {/*               <button onClick={onCancel} type='button' className='button'>Cancel</button> */}
            {/*               <button type='button' className='button'>Save</button> */}
            {/*             </div> */}
            {/*  */}
            {/*           </form> */}
          </div>
        </div>
      </Page>
    </div>
  )
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 90%;
    margin: auto;
  }

  .title-container {
    margin: 20px 0px 10px 0px;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    width: 70%;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: black;
    margin: 0;
  }

  .back-button {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
  }

  .profile-container {
    width: 100%;
    height: 100px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
  }

  .profile-image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 20px;
    /*background: #02c6c0;*/
    background: lightgrey;
  }

  .profile-info-container {
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .profile-info-name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
  }

  .profile-info-edit {
    font-size: 12px;
    font-style: italic;
    text-decoration: underline;
    margin-bottom: 0px;
    color: black;
  }

  .input-label {
    font-size: 12px;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 10px;
  }

  .input {
    padding: 10px 20px;
    border-radius: 20px;
    border: solid 2px #707070;
    width: 100%;
    font-size: 12px;
    margin-bottom: 20px;
  }

  .buttons-container {
    width: 100%;
    margin: 20px 0px 40px 0px;
    display: flex;
    justify-content: space-between;
  }

  .button {
    font-size: 12px;
    font-weight: bold;
    padding: 10px 30px;
    border: none;
    border-radius: 20px;
    background: #c69dff;
    color: black;
  }

  .button:disabled {
    opacity: 0.5;
  }

  .avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileEditProfile
