import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Avatar from 'react-avatar'
import amplitude from 'amplitude-js'

import { logoutUser } from '../../ducks/auth'

import about from '../../images/event/about.jpg'
import agenda from '../../images/event/agenda.jpg'
import speaker from '../../images/event/speaker-info.jpg'

import routes from '../../routes'

const MobileMenu = (props) => {
  const { currentUser } = props
  const history = useHistory()

  const [tab, setTab] = useState('about')

  const dispatch = useDispatch()

  const handleLogout = () => {
    amplitude.getInstance().logEvent('Click Logout')
    dispatch(logoutUser())
  }

  const handleUploadLink = () => {
    history.push('/edit-profile')
  }

  return (
    <div>
      <Page>
        <div className="outer-wrapper">
          <div className="container">
            <div className="title-container">
              <p className="title">Menu</p>
              <Link className="back-button" to={routes.lobby}>
                Back
              </Link>
            </div>

            <div className="profile-container">
              <div className="profile-image">
                {currentUser.avatar ? (
                  <img alt="" src={currentUser.avatar.url} className="avatar" />
                ) : (
                  <Avatar round name={currentUser.fullName} size="70px" />
                )}
              </div>
              <div className="profile-info-container">
                <p className="profile-info-name">{currentUser.fullName}</p>
                <p className="profile-info-edit">
                  <span
                    className="profile-info-edit-text"
                    onClick={handleUploadLink}
                  >
                    Upload your photo
                  </span>
                  <span className="profile-info-edit-text">|</span>
                  <span
                    className="profile-info-edit-text"
                    onClick={handleLogout}
                  >
                    Logout
                  </span>
                </p>
              </div>
            </div>

            <div className="tab-container">
              <p
                onClick={() => {
                  setTab('about')
                }}
                className={tab === 'about' ? 'active' : ''}
              >
                About
              </p>
              <p
                onClick={() => {
                  setTab('agenda')
                }}
                className={tab === 'agenda' ? 'active' : ''}
              >
                Agenda
              </p>
              <p
                onClick={() => {
                  setTab('speakers')
                }}
                className={tab === 'speakers' ? 'active' : ''}
              >
                Speakers
              </p>
            </div>
          </div>

          {tab === 'about' && <img alt="event-about" src={about} className="item-image" />}

          {tab === 'agenda' && (
            <div>
              <img alt="event-agenda" src={agenda} className="item-image" />
            </div>
          )}

          {tab === 'speakers' && (
            <div>
              <img alt="speaker-info" src={speaker} className="item-image" />
            </div>
          )}

          {/*
            tab === 'speakers' &&
            <div className='container'>

              <p className='speaker-day'>Day 1</p>

              <div className='speaker-list'>

                <div className='speaker-item-wrapper'>
                  <div className='speaker-item-container'>
                    <div className='speaker-item-image'></div>
                    <p className='speaker-item-name'>Name</p>
                  </div>
                </div>

                <div className='speaker-item-wrapper'>
                  <div className='speaker-item-container'>
                    <div className='speaker-item-image'></div>
                    <p className='speaker-item-name'>Name</p>
                  </div>
                </div>

                <div className='speaker-item-wrapper'>
                  <div className='speaker-item-container'>
                    <div className='speaker-item-image'></div>
                    <p className='speaker-item-name'>Name</p>
                  </div>
                </div>

                <div className='speaker-item-wrapper'>
                  <div className='speaker-item-container'>
                    <div className='speaker-item-image'></div>
                    <p className='speaker-item-name'>Name</p>
                  </div>
                </div>

              </div>

              <p className='speaker-day'>Day 2</p>

              <div className='speaker-list'>

                <div className='speaker-item-wrapper'>
                  <div className='speaker-item-container'>
                    <div className='speaker-item-image'></div>
                    <p className='speaker-item-name'>Name</p>
                  </div>
                </div>

                <div className='speaker-item-wrapper'>
                  <div className='speaker-item-container'>
                    <div className='speaker-item-image'></div>
                    <p className='speaker-item-name'>Name</p>
                  </div>
                </div>

                <div className='speaker-item-wrapper'>
                  <div className='speaker-item-container'>
                    <div className='speaker-item-image'></div>
                    <p className='speaker-item-name'>Name</p>
                  </div>
                </div>

                <div className='speaker-item-wrapper'>
                  <div className='speaker-item-container'>
                    <div className='speaker-item-image'></div>
                    <p className='speaker-item-name'>Name</p>
                  </div>
                </div>

              </div>

            </div>
          */}
        </div>
      </Page>
    </div>
  )
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 90%;
    margin: auto;
  }

  .title-container {
    margin: 20px 0px 10px 0px;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    width: 70%;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: black;
    margin: 0;
  }

  .back-button {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
  }

  .profile-container {
    width: 100%;
    height: 100px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
  }

  .profile-image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 20px;
    /*background: #02c6c0;*/
    background: lightgrey;
  }

  .profile-info-container {
    width: calc(100% - 90px);
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .profile-info-name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
  }

  .profile-info-edit {
    /*font-size: 12px;*/
    /*font-style: italic;*/
    /*text-decoration: underline;*/
    margin-bottom: 0px;
    /*color: black;*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    width: 100%;
  }

  .profile-info-edit-text {
    font-size: 12px;
    color: black;
    margin-right: 10px;
  }

  .tab-container {
    padding: 5px 0px 20px 0px;
    width: 95%;
    border-bottom: solid 2px #e2cfff;
    margin: auto;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: bold;
    font-style: italic;

    p {
      margin-bottom: 0;
    }

    .active {
      color: #e50000;
    }
  }

  .about-container {
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
  }

  .speaker-day {
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    margin: 20px 0px;
  }

  .speaker-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .speaker-item-wrapper {
    width: 33.33%;
    display: flex;
    justify-content: center;
  }

  .speaker-item-container {
    width: 70px;
    height: 95px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .speaker-item-image {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    border: solid 2px #662283;
    background: #ede1fe;
  }

  .speaker-item-name {
    font-size: 12px;
    text-align: center;
    margin-bottom: 0;
  }

  .item-image {
    margin-top: 10px;
    width: 100%;
  }

  .avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileMenu
