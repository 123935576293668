import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import pinIcon from '../images/icons/pinIcon.png'
import checkIn from '../images/icons/checkin.png'
import { toast } from 'react-toastify'
import Axios from 'axios'
import config from '../config'
import McAgendaItem from './McAgendaItem'

const AgendaWrapper = styled.div`
  padding: 32px 0;
  section.agenda-container {
    .agenda-item {
      .end {
        color: #ffad2c;
      }
      .attend,
      .replay {
        border: none;
        background: transparent;
        height: 30px;
        width: 50px;
        margin: 0;

        img {
          height: 100%;
        }
        .icon-label {
          text-align: center;
          color: white;
          font-size: 1px;
          opacity: 0;
        }
        &:hover {
          img.pending {
            transform: scale(0.6);
          }
          .icon-label {
            font-size: 6.5px;
            opacity: 1;
          }
        }
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`

export default function KfairAgenda({ currentUser }) {
  // const [agendaList, setAgendaList] = useState([
  //   {
  //     id: 1,
  //     start: '09:00 AM',
  //     end: '10:00 AM',
  //     desc: 'Welcome Speech by Alizakri Alias, Chief EPF Officer',
  //     attendance: false,
  //   },
  //   {
  //     id: 2,
  //     start: '10:00 PM',
  //     end: '11:00 AM',
  //     desc: 'Dialogue Session',
  //     attendance: false,
  //   },
  //   {
  //     id: 3,
  //     start: '11:00 AM',
  //     end: '12:00 PM',
  //     desc: 'External Speaker: Chow Sang Hoe, Consulting Leader, Ernst & Young',
  //     attendance: false,
  //   },
  //   {
  //     id: 4,
  //     start: '12:00 PM',
  //     end: '02:30 PM',
  //     desc: `Breakout Session with Dato' Mohd Naim Daruwish,
  //       Chief Operations Officer for: <br/>
  //       - HODs of Operations Division<br />
  //       - Head of State/Branch
  //     `,
  //     attendance: false,
  //   },
  //   {
  //     id: 5,
  //     start: '02:30 PM',
  //     end: '',
  //     desc: `Breakout Session with Nurhisham Hussein,
  //       Chief Strategy Officer for: <br/>
  //       - HODs of CEO's Office<br />
  //       - HODs of Digital Technology Division<br/>
  //       - HODs of Finance & Services Division<br/>
  //       - HODs of Investment Division<br/>
  //       - HODs of Strategy Division
  //     `,
  //     attendance: false,
  //   },
  // ])
  const [agendaList, setAgendaList] = useState([]);

  useEffect(() => {
    Axios({
      url: `${config.apiUrl}/api/v1/hall_sessions?hall_id=2`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      },
    }).then((result) => {
      // console.log(result.data.sessions);
      setAgendaList(result.data.sessions);
    })
  }, [])

  // const takeAttendance = (id) => {

  //   if (agendaList.filter(item => item.id === id)[0].attendance === true) return;

  //   Axios({
  //     method: 'post',
  //     url: `${config.apiUrl}/api/v1/hall_sessions/${id}/check_in`,
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
  //     },
  //   })
  //     .then((response) => {
  //       // console.log(response)
  //       toast(`Check-in successful`)
  //       const newAgendaList = agendaList.map((item, index) => (
  //         {
  //           ...item,
  //           attendance: item.id === id ? true : item.attendance
  //         }
  //       ));
  //       setAgendaList(newAgendaList);
  //     })
  //     .catch((err) => {
  //       // console.error(err.response.data.error);
  //       if (err && err.response && err.response.data && err.response.data.error) {
  //         toast(err.response.data.error);
  //       } else {
  //         toast('Check-in failed. Something went wrong');
  //       }
  //     })
  // }

  return (
    <AgendaWrapper>
      <section className="agenda-container">
        <div>
          {agendaList.map((agenda) => (
            <McAgendaItem agenda={agenda} agendaList={agendaList} setAgendaList={setAgendaList} key={agenda.id}/>
          ))}
        </div>
      </section>
    </AgendaWrapper>
  )
}
