import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import amplitude from 'amplitude-js'

import LoadIcon from '../../components/LoadIcon'
import ChatSection from '../../components/ChatSection.js'
import SideAgenda from '../../components/SideAgenda.js'

import { muteMusic, unmuteMusic } from '../../ducks/layout'

import openTheatre from '../../images/mobile/open-theatre.png'
import closeTheatre from '../../images/mobile/close-theatre.png'
import screen from '../../images/mobile/screen.png'
import checkinPlayback from '../../images/mobile/checkin-playback.png'
import { ChatKfairDb } from '../../firebaseConfig'
import config from '../../config'
// import kfair20Title from '../../images/mobile/kfair20-title.png'

const MobileKFair20 = ({ currentUser }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    amplitude.getInstance().logEvent(`Visit KFAIR20 Auditorium`)
  }, [currentUser.id])

  useEffect(() => {
    dispatch(muteMusic())

    return () => {
      if (localStorage.getItem('muted') === 'false') {
        dispatch(unmuteMusic())
      }
    }
  }, [dispatch])

  const [loading, setLoading] = useState(true)
  const [sidebar, setSidebar] = useState(false)
  const [theatre, setTheatre] = useState(false)

  const toggleSidebar = () => {
    amplitude.getInstance().logEvent(`Click KFAIR20 checkin list`)
    setSidebar(!sidebar)
  }

  const videoContainerTheatreStyle = {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'black',
    zIndex: '99',
  }

  return (
    <Page>
      <SideAgenda
        open={sidebar}
        toggleSidebar={toggleSidebar}
        hallNumber={1}
        currentUser={currentUser}
      />

      <div className="outer-wrapper">
        <div className="container">
          <div className="checkin-container">
            <img
              src={checkinPlayback}
              className="checkin"
              onClick={toggleSidebar}
            />
          </div>

          <div className="screen-container">
            <img src={screen} className="screen" alt="screen" />
            <div
              className="video-container"
              style={theatre ? videoContainerTheatreStyle : {}}
            >
              {loading && <LoadIcon />}
              <iframe
                title="Live Broadcast"
                src="https://player.vimeo.com/video/505055532?autoplay=1"
                allow="autoplay; fullscreen"
                allowFullScreen
                frameBorder="0"
                onLoad={() => {
                  setLoading(false)
                }}
              />
            </div>
          </div>

          <button
            type="button"
            className="theatre-button"
            onClick={() => setTheatre(true)}
          >
            <img src={openTheatre} className="theatre-button-icon" alt="theatre-icon" />
            <p className="theatre-button-label">Theatre mode</p>
          </button>

          {/* <img src={kfair20Title} className="title" /> */}
        </div>

        <div className="chat-container">
          <ChatSection
            currentUser={currentUser}
            firebaseDb={ChatKfairDb}
            hallName="KFAIR20"
          />
        </div>

        {theatre && (
          <div className="theatre-close">
            <img
              src={closeTheatre}
              onClick={() => setTheatre(false)}
              className="theatre-close-icon"
              alt="close-icon"
            />
          </div>
        )}
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: url(${config.assets.auditorium1.portrait});
  background-size: cover;
  background-position: 50% 50%;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 95%;
    margin: auto;
  }

  .checkin-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /*background: orange;*/
    margin: 30px 0px 20px 0px;
  }

  .checkin {
    width: 38%;
    height: auto;
  }

  .screen-container {
    width: 100%;
    height: 0;
    padding-bottom: 58.5%;
    position: relative;
  }

  .screen {
    width: 100%;
    position: absolute;
  }

  .video-container {
    width: 92%;
    height: 81%;
    background: #1e1e1e;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50.5%, -51%);

    iframe {
      height: 100%;
      width: 100%;
    }
  }

  .title {
    width: 100%;
    margin: 20px 0px;
  }

  .chat-container {
    width: 100%;
    height: 500px;
    margin-top: 36px;
  }

  .theatre-button {
    width: 140px;
    height: 30px;
    background: black;
    margin: 10px 10px 0px 0px;
    float: right;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    opacity: 0.6;
    padding: 0;
  }

  .theatre-button-label {
    color: white;
    font-size: 12px;
    margin: 0px 10px 0px 0px;
  }

  .theatre-button-icon {
    width: 20px;
    margin: 0px 10px;
  }

  .theatre-close {
    color: white;
    background: black;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 99;
    opacity: 0.6;
  }

  .theatre-close-icon {
    width: 20px;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileKFair20
