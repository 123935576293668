import LogoL from './images/logo.png'
import Entrance from './images/backdrops/entrance.jpg'
import EntranceM from './images/backdrops/entranceP.jpg'
import LobbyL from './images/backdrops/lobby.jpg'
import Exhibition from './images/backdrops/exhibition-hall.jpg'
import Auditorium from './images/backdrops/auditorium.jpg'
import AuditoriumM from './images/backdrops/auditoriumP.jpg'
import MC from './images/backdrops/MC20.png'
import NetworkingL from './images/backdrops/networking.jpg'
import LobbyMusic from './images/audio/lobby.mp3'
import routes from './routes'
import Gallerium from './images/backdrops/gallerium.jpg'

const dev = {
  apiUrl: 'http://localhost:3000',
}

const prod = {
  apiUrl: 'https://mcpdemo.herokuapp.com',
}

const API_KEYS = {
  amplitude: '',
}

const config = process.env.NODE_ENV === 'production' ? prod : dev

const assets = {
  logo: {
    landscape: LogoL,
    portrait: LogoL,
  },
  entrance: {
    landscape: Entrance,
    portrait: EntranceM,
  },
  lobby: {
    landscape: LobbyL,
    portrait: EntranceM,
  },
  auditorium1: {
    landscape: Auditorium,
    portrait: AuditoriumM,
  },
  auditorium2: MC,

  exhibition1: Exhibition,
  exhibition2: Exhibition,

  networking: {
    landscape: NetworkingL,
    portrait: NetworkingL,
  },
  gallerium: Gallerium,

  lobbyMusic: LobbyMusic,
}

const layout = {
  auditoriumVid: `
    top: 13%;
    bottom: 40.5%;
    left: 20%;
    right: 35.1%;
  `,
}

const lobbyEntries = [
  {
    id: 'auditorium',
    destination: routes.auditorium1,
    // destination: [
    //   { name: 'KFAIR20', url: routes.auditorium1 },
    //   { name: 'MC20', url: routes.auditorium2 },
    // ],
    css: `
      top: 80.2%;
      left: 23%;
      right: 72%;
      bottom: 20.3%;
    `,
  },
  {
    id: 'exhibition',
    // destination: routes.exhibition1,
    destination: [
      { name: 'Hall A', url: routes.exhibition1 },
      { name: 'Hall B', url: routes.exhibition2 },
    ],
    css: `
      top: 80%;
      left: 37%;
      right: 58%;
      bottom: 20.5%;
    `,
  },
  {
    id: 'networking',
    destination: routes.networking,
    css: `
      top: 80%;
      left: 59.8%;
      right: 34%;
      bottom: 20.5%;
    `,
  },
  {
    id: 'gallerium',
    destination: routes.gallerium,
    css: `
      top: 81.5%;
      left: 76.4%;
      right: 16.5%;
      bottom: 19.5%;
    `,
  },
  {
    id: 'helpdesk',
    destination: 0,
    css: `
      top: 80.5%;
      left: 3.5%;
      right: 93.5%;
      bottom: 19.5%;
    `,
  },
]

export default {
  API_KEYS,
  assets,
  layout,
  lobbyEntries,

  eventName: 'Medical Conference Partners',
  // googleAnalyticsID: 'UA-185128388-1',

  ...config,
}
