import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import amplitude from 'amplitude-js'

import PageWithBg from '../components/PageWithBg'
import LoadIcon from '../components/LoadIcon'
import { useDispatch } from 'react-redux'
import { showGlobalModal, muteMusic, unmuteMusic } from '../ducks/layout'
import config from '../config'
import { SIZES } from '../styles/theme'
import attendance from '../images/icons/task.png'
import ChatSection from '../components/ChatSection'
import SideAgenda from '../components/SideAgenda'
import Logo from '../images/backdrops/auditorium_logo.png'
import { Redirect } from 'react-router-dom'
import routes from '../routes'
import { ChatKfairDb, ChatMcDb } from '../firebaseConfig'

const Page = styled.div`
  overflow: hidden;
  .content {
    ${config.layout.auditoriumVid}
    position: absolute;
    background: black;

    iframe {
      height: 100%;
      width: 100%;
    }
  }
  .chat {
    max-width: 350px;
    position: fixed;
    padding-top: ${SIZES.navHeight};
    top: 0;
    left: 67%;
    bottom: 0;
    right: 5%;
    z-index: 30;
  }
  .attendance {
    position: fixed;
    top: 10%;
    left: 5%;
    display: flex;
    animation: heartbeat 5s infinite;

    .action-label {
      color: white;
      font-weight: 500;
      margin: 0 16px;
      font-style: italic;
      align-self: center;
    }
    button {
      background: transparent;
      border: none;
      padding: 0;
      margin: 0 auto;
      img {
        border-radius: 50%;
      }
    }
    &:hover {
      .action-label,
      img {
        transition: 500ms;
        transform: scale(1.2);
      }
      img {
        box-shadow: 0px 0px 30px 6px #ffad2c;
      }
    }
  }
  .actions {
    position: fixed;
    top: 72%;
    bottom: 23%;
    left: 27%;
    right: 42%;
    display: flex;
    justify-content: space-between;
    button {
      width: 150px;
      background-image: linear-gradient(#492777, #c4000c);
      border: 2px solid #e20613;
      border-radius: 16px;
      color: white;
      font-style: italic;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 1px;
      &:hover {
        box-shadow: 0px 10px 10px -10px #e20613;
        animation-name: bounce;
        animation-duration: 1s;
        animation-fill-mode: both;
      }
    }
  }
  #mc-logo {
    width: 15%;
    position: fixed;
    top: 33%;
    bottom: 40%;
    left: 2%;
  }
`

export default function AuditoriumPage({
  liveUrl,
  hallBg,
  hallNumber,
  currentUser,
}) {
  const [loading, setLoading] = useState(true)
  const [sidebar, setSidebar] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(muteMusic())

    return () => {
      if (localStorage.getItem('muted') === 'false') {
        dispatch(unmuteMusic())
      }
    }
  }, [dispatch])

  useEffect(() => {
    document.title = `${config.eventName} | Conference`
  }, [])

  useEffect(() => {
    amplitude
      .getInstance()
      .logEvent(`Visit ${hallNumber === 1 ? 'KFAIR20' : 'MC20'} Auditorium`)
  }, [currentUser.id, hallNumber])

  const displaySpeaker = () => {
    amplitude.getInstance().logEvent('Click MC20 speaker modal')
    dispatch(showGlobalModal('speaker'))
  }
  const displayAgenda = () => {
    amplitude.getInstance().logEvent('Click MC20 agenda modal')
    dispatch(showGlobalModal('agenda'))
  }

  const toggleSidebar = () => {
    if (sidebar === false)
      amplitude
        .getInstance()
        .logEvent(`Click ${hallNumber === 1 ? 'KFAIR20' : 'MC20'} checkin list`)
    setSidebar(!sidebar)
  }

  if (currentUser.role) {
    if (hallNumber === 2 && currentUser?.role !== 'manager') {
      return <Redirect to={routes.lobby} />
    }
    return (
      <Page>
        <SideAgenda
          open={sidebar}
          toggleSidebar={toggleSidebar}
          hallNumber={hallNumber}
          currentUser={currentUser}
        />
        <PageWithBg bgImg={hallBg}>
          <div className="content">
            {loading && <LoadIcon />}
            <iframe
              title="Live Broadcast"
              src={liveUrl}
              allow="autoplay; fullscreen"
              allowFullScreen
              frameBorder="0"
              onLoad={() => {
                setLoading(false)
              }}
            />
          </div>
          <div className="chat h-100">
            <ChatSection
              currentUser={currentUser}
              firebaseDb={hallNumber === 1 ? ChatKfairDb : ChatMcDb}
              hallName={hallNumber === 1 ? 'KFAIR20' : 'MC20'}
            />
          </div>
          {hallNumber === 1 ? (
            <>
              <div className="attendance">
                <button onClick={toggleSidebar}>
                  <img src={attendance} width="60" alt="attendance" />
                </button>
                <small className="action-label">
                  Check-in/
                  <br />
                  Playback
                </small>
              </div>
              <div id="mc-logo">
                <img src={Logo} width="100%" alt="logo" />
              </div>
            </>
          ) : (
            <>
              <div className="attendance">
                <button onClick={toggleSidebar}>
                  <img src={attendance} width="60" alt="attendance" />
                </button>
                <small className="action-label">Check-in</small>
              </div>
              <div className="actions">
                <button onClick={displayAgenda}>Agenda</button>
                <button onClick={displaySpeaker}>Speakers</button>
              </div>
              <div id="mc-logo">
                <img src={Logo} width="100%" alt="logo" />
              </div>
            </>
          )}
        </PageWithBg>
      </Page>
    )
  }
  return <LoadIcon />
}
